<template>
  <div class="ds-container">
    <section class="ds-header">
      <div class="ds-title">
        <h2>Relatório de guias</h2>
        <span id="help-icon" class="icon-box">
          <HelpCircle class="icon stroke" />
        </span>
        <b-tooltip target="help-icon" placement="bottom">
          Visualize o relatório das guias cadastradas.
        </b-tooltip>
      </div>
      <!-- <div class="no-print">
          <b-button
            class="wh-button"
            variant="outline-primary"
            outline
            @click="print"
          >
            Imprimir
          </b-button>
        </div> -->
      <section class="buttons">
        <div class="btn-title-container">
          <b-button
            class="wh-button new-tiss"
            variant="primary"
            @click="openSetBatchModal"
            id="setBatchButton"
            :disabled="selectedGuides.length === 0"
          >
            Vincular guia(s) ao lote
          </b-button>
          <b-tooltip
            target="setBatchButton"
            triggers="hover"
            placement="bottom"
          >
            Selecione as guias para vincular a um lote
          </b-tooltip>

          <b-button
            class="wh-button new-tiss"
            variant="primary"
            @click="automaticBatchCreate"
          >
            Gerar lote automático
          </b-button>

          <b-button
            class="wh-button new-tiss"
            variant="primary"
            @click="openNewSusGuideModal"
          >
            <Plus class="icon-plus mr-2 my-auto" />
            Criar nova guia
          </b-button>
        </div>
      </section>
    </section>
    &nbsp;
    <section>
      <GuideReportContainer @selected-guides="setSelectedGuides" :type="'BPA'" :key="reloadContainer"/>
    </section>
  </div>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
import api from '@/modules/sus/api'

export default {
  components: {
    HelpCircle: () => import('@/assets/icons/help-circle.svg'),
    Plus: () => import('@/assets/icons/plus.svg'),
    GuideReportContainer: () =>
      import('@/layouts/InvoicingSus/GuideReportContainer.vue')
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      selectedGuides: [],
      reloadContainer: 1
    }
  },
  props: {
    print: Function
  },
  methods: {
    openNewSusGuideModal() {
      this.$bvModal.show('new-sus-guide-modal')
    },
    openSetBatchModal() {
      this.$bvModal.show('set-batch-modal')
    },
    setSelectedGuides(guides) {
      this.selectedGuides = guides
    },

    async automaticBatchCreate() {
      console.log('entrou');
      const isLoading = this.$loading.show()
      try {
        const {data} = await api.getSusGuidesForAutomaticBatch(this.clinic.id, 'Bpa')
        this.batchesToCreate = data          
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.confirmBatchGenerate()
        isLoading.hide()
      }
    },
    confirmBatchGenerate(){
      const table = this.getHtmlTable();

      this.$swal.fire({
        title: 'Gerar lote automático',
        html: table,
        showCancelButton: true,
        confirmButtonColor: '#305BF2',
        confirmButtonText: 'Confirmar',
        cancelButtonColor: 'transparent',
        cancelButtonText: '<span style="color: red;">Cancelar</span>',
        cancelButtonHoverColor: '#ff0000',
        showCloseButton: true,
        reverseButtons: true,
        customClass: {
          popup: "custom-swal-batch-review",
        },
      }).then(async (result) => {
        if(result.isConfirmed){
          const isLoading = this.$loading.show()
          try {
            const payload = {
              guide_type: 'BPA',
              clinic_id: this.clinic.id,
              batches: this.batchesToCreate,                
            }
            api.createAutomaticSusBatches(payload)
              .then(() => {
                this.$toast.success('Lotes gerados com sucesso!')
                this.reloadContainer++
              })
              .catch(err => {
                this.$toast.error(err.message)
              })
            
          } catch (error) {
            this.$toast.error(error.message)
          } finally {
            isLoading.hide()
          }
        }
      })
    },
    getHtmlTable(){
      let tablesHtml = `
      <div style=" padding: 10px;margin-bottom: 20px;font-family: Nunito Sans;font-weight: 600;line-height: 24px;text-align: center;">
        <div>
          <svg width="96" height="97" viewBox="0 0 96 97" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 32.5C0 14.8269 14.3269 0.5 32 0.5H64C81.6731 0.5 96 14.8269 96 32.5V64.5C96 82.1731 81.6731 96.5 64 96.5H32C14.3269 96.5 0 82.1731 0 64.5V32.5Z" fill="#E7ECFE"/>
          <path d="M17.1431 33.0731C17.1431 24.5521 24.0507 17.6445 32.5716 17.6445H63.4288C71.9497 17.6445 78.8573 24.5521 78.8573 33.0731V63.9302C78.8573 72.4512 71.9497 79.3588 63.4288 79.3588H32.5716C24.0507 79.3588 17.1431 72.4512 17.1431 63.9302V33.0731Z" fill="#305BF2"/>
          <path d="M47.9992 63.4974C56.2833 63.4974 62.9989 56.7818 62.9989 48.4977C62.9989 40.2136 56.2833 33.498 47.9992 33.498C39.7151 33.498 32.9995 40.2136 32.9995 48.4977C32.9995 56.7818 39.7151 63.4974 47.9992 63.4974Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M47.999 40.998V50.7478" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M47.999 55.9971H48.014" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        Atenção! Será(ão) gerados(s) ${this.batchesToCreate?.length} lote(s) BPA:
      </div>
      <div class="custom-batch-review-tables-container">`
      this.batchesToCreate.forEach(batch => {
        tablesHtml += `<div>
          <span style="color: blue;"> Lote n°${batch.number} </span> - <span style="color:grey">${batch.count} guia(s) - ${batch.sheet} folha(s)</span>
        </div>`
      });

      tablesHtml += '</div>';

      return tablesHtml;
    }
  }
}
</script>

<style lang="scss" scoped>
.ds-container {
  padding: 30px 10px;
  .ds-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 30px 0;
  }
  .ds-title {
    h2 {
      display: inline-flex;
      align-items: center;
      font-family: 'Red Hat Display';
      font-weight: 500;
      font-size: 18px;
    }

    .icon {
      height: 24px;
      width: 24px;
      margin-left: 5px;
      stroke: var(--neutral-500);
    }
  }
  .buttons {
    margin-left: auto;
    .new-tiss {
      display: flex;
      font-weight: 700;
      font-size: 16px;
      margin: 0 auto;
      .icon-plus {
        width: 16px;
        height: 16px;
        fill: var(--neutral-000) !important;
      }
    }
  }
  .btn-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    button {
      font-weight: bold;
    }

    .btn-primary {
      padding: 4px 15px;
    }

    .upload-icon {
      height: 22px;
      width: 22px;
      margin-right: 7px;
      margin-bottom: 3px;
      overflow: inherit;
      fill: var(--neutral-000);
    }
  }
}
</style>
